import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bold',
  pure: true,
  standalone: true,
})
export class BoldPipe implements PipeTransform {
  public transform(value: string | number): string {
    return `<b>${value}</b>`;
  }
}
